import React from 'react';
import FiberOpticJunctionBox from '../../assets/icons/CAIXA_DE_FIBRA.svg';

const FiberOpticJunctionBoxButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={FiberOpticJunctionBox} alt={label} data-testid="fiberoptic-icon" width="20px" height="20px"/>
    </button>
  );
};

export default FiberOpticJunctionBoxButton;