import React from 'react';
import DroneIcon from '../../assets/icons/drone.svg';

const DroneIconButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={DroneIcon} alt={label} data-testid="drone-icon" width="30px" height="30px" />
    </button>
  );
};

export default DroneIconButton;