import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationBar = ({ currentPage, totalPages, setCurrentPage}) => {
  const [pageLimit, setPageLimit] = useState(10);

  useEffect(() => {
    const updatePageLimit = () => {
      if (window.innerWidth < 800) {
        setPageLimit(5);
      } else {
        setPageLimit(10);
      }
    };

    updatePageLimit();

    window.addEventListener('resize', updatePageLimit);

    return () => {
      window.removeEventListener('resize', updatePageLimit);
    };
  }, []);

  let start = Math.max(0, currentPage - 4);

  let end = Math.min(totalPages, start + pageLimit);

  function handlePagination(page) {
      setCurrentPage(page - 1);
  }

  return (
    <Pagination aria-label="pagination">
      <PaginationItem disabled={currentPage === 0}>
        <PaginationLink first onClick={() => handlePagination(1)} />
      </PaginationItem>

      <PaginationItem disabled={currentPage === 0}>
        <PaginationLink
          previous
          onClick={() => handlePagination(currentPage - 1)}
        />
      </PaginationItem>

      {[...Array(totalPages)].map((_, index) => {
        if (index >= start && index < end) {
          return (
            <PaginationItem
              active={index === currentPage}
              key={index}
            >
              <PaginationLink onClick={() => handlePagination(index + 1)}>
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          );
        }
        return null;
      })}

      <PaginationItem disabled={currentPage >= totalPages - 1}>
        <PaginationLink
          next
          onClick={() => handlePagination(currentPage + 1)}
        />
      </PaginationItem>

      <PaginationItem disabled={currentPage >= totalPages - 1}>
        <PaginationLink last onClick={() => handlePagination(totalPages)} />
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationBar;
