import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Header from "../../elements/Header";
import { Tooltip } from "react-tooltip";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import CheckPermission from "../Auth/CheckPermission";
import {
  BsArrowLeft,
  BsCheck,
  BsFillNodeMinusFill,
  BsFillNodePlusFill,
  BsFillPencilFill,
  BsFillPlusCircleFill,
} from "react-icons/bs";
import { formatCnpj, formatPhone } from "../../elements/FormatDateString";
import PaginationBar from "../../elements/PaginationBar/PaginationBar";

const CompaniesManagement = () => {

  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); 
  const [totalUsers, setTotalUsers] = useState(0); 
  const pageSize = 20;

  let { id: companyId } = useParams();
  let apiUrl = process.env.REACT_APP_API_URL;
  const [userPermission, setUserPermission] = useState(null);
  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    button: "",
    buttonHref: "",
    buttonAction: "",
    buttonSecAction: "",
  });
  const toggleModal = (content) => {
    if (!content.title) content.title = "";
    if (!content.content) content.content = "";
    if (!content.button) content.button = "Ok";
    if (!content.buttonHref) content.buttonHref = null;
    if (!content.buttonAction) content.buttonAction = null;
    if (!content.buttonSecAction) content.buttonSecAction = null;

    setModalContent(content);
    setModal(!modal);
  };
  const [permissionsData, setPermissionsData] = useState([]);
  function getPermissionsData() {
    fetch(apiUrl + "/users/roles", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}
        setPermissionsData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const [showDisabledItens, setShowDisabledItens] = useState(false);

  const [companies, setCompanies] = useState();
  const getCompanies = () => {
    getPermissionsData();
    setCompanies();
    let link = apiUrl + "/companies";
    if (companyId > 0) link += "/" + companyId;
    fetch(link, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}

        if (companyId > 0) setCompanies([data]);
        else {
          setCompanies(sortList(data.content, "enabled"));
        }
      })
      .finally(() => {
        if (companyId > 0) getUsers();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sortList = (list, field) => {
    return list.sort(function (a, b) {
      if (a[field] > b[field]) {
        return -1;
      } else {
        return true;
      }
    });
  };

  useEffect(() => {
    if (userPermission && companyId) {
      getUsers();
    }
  }, [currentPage, userPermission, companyId]);

  const getUsers = () => {
    setUsers();
    const paged = true; // Ativa paginação
    const pageNumber = currentPage; // Página atual, controlada com useState
    const link = `${apiUrl}/companies/${companyId ? companyId : "0"}/employees?page=${pageNumber}&size=${pageSize}`;
    fetch(
      link,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          //data = JSON.parse(data);
          const parsedData = JSON.parse(data);
          setTotalUsers(parsedData.totalElements); 
          setUsers(parsedData.content); 
        } catch (error) {}

        if (localStorage.getItem("robotictech_role") !== "ADMIN") {
          data.content = data.content.filter(
            (itemContent) => itemContent.role !== "ADMIN"
          );
        }

        setUsers(sortList(data.content, "enabled"));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status" id="loading">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  };

  // Início função de requisição de edição do usuario pela API

  const disableEnableUser = (id, status) => {
    toggleModal({
      title: modalContent.title,
      content: getLoading(),
      buttonAction: () => setModal(false),
      buttonHref: null,
    });
    //console.log(id);
    fetch(
      apiUrl + "/users/" + id + (status === "disable" ? "/disable" : "/enable"),
      {
        method: "PUT",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",

          Authorization: "Bearer " + userPermission.access_token,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
        // if (!response.ok || response.status !== 200) {
        //   return response.text().then((text) => {
        //     throw new Error(text);
        //   });
        // } else {
        //   return response.text();
        // }
      })
      .then((data) => {
        toggleModal({
          title: modalContent.title,
          content:
            "Usuário " +
            (status === "disable" ? "desabilitado" : "habilitado") +
            " com sucesso.",

          buttonAction: () => setModal(false),
          buttonHref: null,
        });
      })
      .finally(() => {
        getUsers();
      })
      .catch((error) => {
        toggleModal({
          title: modalContent.title,
          content:
            "Não foi possível " +
            (status === "disable" ? "desabilitar" : "habilitar") +
            " o usuário. " +
            error,

          buttonAction: () => setModal(false),
          buttonHref: null,
        });
        console.error("Error:", error);
      });
  };
  // Fim função de requisição de edição do usuario pela API

  // Início função de requisição de exclusão do usuario pela API

  // const deleteUser = (id) => {
  //   toggleModal({
  //     title: modalContent.title,
  //     content: getLoading(),
  //     buttonAction: () => setModal(false),
  //     buttonHref: null,
  //   });
  //   fetch(apiUrl + "/users/" + id, {
  //     method: "DELETE",
  //     headers: {
  //       accept: "*/*",
  //       "Content-Type": "application/json",

  //       Authorization: "Bearer " + userPermission.access_token,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.text();
  //       } else {
  //         throw new Error("Erro");
  //       }
  //     })
  //     .then((data) => {
  //       toggleModal({
  //         title: modalContent.title,
  //         content: "Usuário apagado com sucesso.",

  //         buttonAction: () => setModal(false),
  //         buttonHref: null,
  //       });
  //     })
  //     .finally(() => {})
  //     .catch((error) => {
  //       toggleModal({
  //         title: modalContent.title,
  //         content: "Não foi possível apagar o usuário. " + error,

  //         buttonAction: () => setModal(false),
  //         buttonHref: null,
  //       });
  //       console.error("Error:", error);
  //     });
  // };
  // Fim função de requisição de exclusão do usuario pela API

  // Início função de requisição de edição da empresa pela API

  const disableEnableCompany = (id, status) => {
    toggleModal({
      title: modalContent.title,
      content: getLoading(),
      buttonAction: () => setModal(false),
      buttonHref: null,
    });
    //console.log(id);

    fetch(apiUrl + "/companies/" + id, {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",

        Authorization: "Bearer " + userPermission.access_token,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Erro");
        }
      })
      .then((data) => {
        try {
          data = JSON.parse(data);
        } catch (error) {}

        if (status === "disable") {
          data.enabled = false;
        } else {
          data.enabled = true;
        }

        fetch(apiUrl + "/companies/" + id, {
          method: "PUT",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + userPermission.access_token,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              return response.text();
            } else {
              throw new Error("Erro");
            }
          })
          .then((data) => {
            toggleModal({
              title: modalContent.title,
              content:
                "Empresa " +
                (status === "disable" ? "desabilitada" : "habilitada") +
                " com sucesso.",

              buttonAction: () => window.location.reload(false),
              buttonHref: null,
            });
          })
          .finally(() => {
            getUsers();
          })
          .catch((error) => {
            toggleModal({
              title: modalContent.title,
              content:
                "Não foi possível " +
                (status === "disable" ? "desabilitar" : "habilitar") +
                " a empresa. " +
                error,

              buttonAction: () => setModal(false),
              buttonHref: null,
            });
            console.error("Error:", error);
          });
      });
  };
  // Fim função de requisição de edição do usuario pela API

  useEffect(() => {
    if (userPermission) getCompanies();
    // eslint-disable-next-line
  }, [userPermission]);
  return (
    <div className="p-2">
      {/* Cabeçalho da página */}
      <Header />
      <Modal isOpen={modal} toggle={toggleModal} backdrop="static">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalBody>{modalContent.content}</ModalBody>
        <ModalFooter>
          {modalContent.buttonSec &&
          modalContent.buttonSecStyle &&
          modalContent.buttonSecStyle ? (
            <Link
              className={"btn btn-" + modalContent.buttonSecStyle}
              onClick={modalContent.buttonSecAction}
            >
              {modalContent.buttonSec}
            </Link>
          ) : (
            ""
          )}
          <Link
            className={
              "btn btn-" +
              (modalContent.buttonStyle
                ? modalContent.buttonStyle
                : "outline-secondary")
            }
            to={modalContent.buttonHref ? modalContent.buttonHref : false}
            onClick={
              modalContent.buttonAction ? modalContent.buttonAction : false
            }
          >
            {modalContent.button}
          </Link>
        </ModalFooter>
      </Modal>
      <Tooltip id="my-tooltip" />
      {companyId > 0 ? (
        <Container
          className="p-0"
          style={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          {/* Início da exibição da lista de empresas */}
          <div className="container p-0" style={{ marginTop: "10vh " }}>
            <div
              id="basicInformation"
              className="container /*main-content*/ recuadoOut"
            >
              <div className="row pt-4 m-0">
                <Row className="m-0">
                  <Col className="mt-auto mb-auto">
                    <h2 className="m-0 text-start">
                      {/* Inicio botão voltar pagina */}
                      <div className="m-2 ms-0" style={{ display: "flex" }}>
                        <Link
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            CheckPermission(
                              userPermission && userPermission.role
                                ? userPermission.role
                                : "",
                              "ADMIN"
                            )
                              ? "Voltar para a lista de empresas"
                              : "Voltar para a página inicial"
                          }
                          className="text-end mt-auto mb-auto"
                          style={{ marginRight: "0px" }}
                          to={
                            CheckPermission(
                              userPermission && userPermission.role
                                ? userPermission.role
                                : "",
                              "ADMIN"
                            )
                              ? "/companiesManagement"
                              : "/home"
                          }
                        >
                          <BsArrowLeft
                            className="nav-button-circle "
                            style={{ boxShadow: "none" }}
                          />
                        </Link>
                        {/* Final Botão voltar pagina */}
                        {companies && companyId
                          ? companies.map((comp) => {
                              if (comp.id.toString() === companyId.toString()) {
                                return (
                                  <div
                                    key={Math.random()}
                                    className="mt-auto mb-auto"
                                  >
                                    <img
                                      key={Math.random()}
                                      alt=""
                                      className="me-3"
                                      style={{ maxWidth: "100px" }}
                                      src={
                                        comp.logo_image_path
                                          ? comp.logo_image_path +
                                            "?" +
                                            performance.now()
                                          : "/imgs/logo_empty.png"
                                      }
                                    />
                                    {comp.name}
                                  </div>
                                );
                              } else {
                                return false;
                              }
                            })
                          : ""}
                      </div>
                    </h2>
                    <Tooltip id="my-tooltip" />
                  </Col>
                  <Col lg={4} className="pr-0 m-0 row">
                    <div className="text-end row m-0 p-0">
                      {/* Início botão de adicionar novo usuario */}
                      {CheckPermission(
                        userPermission && userPermission.role
                          ? userPermission.role
                          : "",
                        "MANAGER"
                      ) ? (
                        <div className=" m-auto" key={Math.random()}>
                          <Link
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Criar um novo usuário"
                            className="text-end"
                            style={{ marginRight: "0px" }}
                            to={"/novousuario/" + companyId}
                          >
                            Novo usuário
                            <BsFillPlusCircleFill className="nav-button-circle " />
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* Fim botão de adicionar novo usuario */}
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", padding: "5px" }}>
                <div style={{ width: "100%" }}>
                  <hr key={Math.random()} />

                  {users ? (
                    users.length > 0 ? (
                      <div key={Math.random()}>
                        <Row
                          className="m-0 p-0"
                          onClick={() =>
                            setShowDisabledItens(!showDisabledItens)
                          }
                        >
                          <Link className="row m-0" style={{ width: "auto" }}>
                            <div
                              className="p-0 ms-0 mt-0 mb-0 me-3"
                              style={{
                                width: "20px",
                                height: "20px",
                                border: "1px solid #CCC",
                                borderRadius: "6px",
                              }}
                            >
                              {showDisabledItens ? (
                                <BsCheck
                                  className=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    marginTop: "-10px",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            Mostrar usuários desabilitados
                          </Link>
                        </Row>
                        <div className="d-none d-lg-block ">
                          <Row className="mb-0 me-2 ms-2 mt-2 p-2">
                            <Col className="text-start row m-0" sm={12} lg={4}>
                              <div className="m-auto">
                                <b>Nome</b>
                              </div>
                            </Col>
                            <Col className="text-center row m-0" sm={12} lg={4}>
                              <div className="m-auto">
                                <b>Contato</b>
                              </div>
                            </Col>
                            <Col className="text-center row m-0" sm={12} lg={2}>
                              <div className="m-auto">
                                <b>Nível</b>
                              </div>
                            </Col>
                            <Col className="text-center row m-0" sm={12} lg={2}>
                              <div className="m-auto">
                                <b>Ações</b>
                              </div>
                            </Col>
                          </Row>
                          <hr className="mt-0 pt-0 me-2 ms-2" />
                        </div>
                        {users.map((user) => {
                          let showUser = false;
                          if (showDisabledItens) {
                            showUser = true;
                          } else {
                            if (user.enabled === true) showUser = true;
                          }

                          if (showUser) {
                            return (
                              <Row
                                key={Math.random()}
                                className={
                                  "m-2 p-2 " +
                                  (user.enabled ? "bg-white" : "bg-light")
                                }
                                style={{
                                  boxShadow: "0px 0px 5px #CCC",
                                  borderRadius: "5px",
                                }}
                              >
                                <Col
                                  className="text-start row m-0"
                                  sm={12}
                                  lg={4}
                                >
                                  <div className="m-auto">
                                    {user.first_name ? user.first_name : ""}{" "}
                                    {user.last_name ? user.last_name : ""}
                                    <br />
                                    <small>
                                      {user.username
                                        ? "(" + user.username + ")"
                                        : ""}
                                    </small>
                                  </div>
                                  <hr
                                    className="m-1 p-1 d-block d-lg-none"
                                    style={{ color: "rgba(0,0,0,0.2)" }}
                                  />
                                </Col>
                                <Col
                                  className="text-start text-lg-center row m-0"
                                  sm={12}
                                  lg={4}
                                >
                                  <div
                                    className="m-auto"
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    {user.email ? user.email : ""}
                                    {/* <br />
                                    <small>
                                      {user.phone
                                        ? formatPhone(user.phone)
                                        : ""}
                                    </small> */}
                                  </div>
                                  <hr
                                    className="m-1 p-1 d-block d-lg-none"
                                    style={{ color: "rgba(0,0,0,0.2)" }}
                                  />
                                </Col>
                                <Col
                                  className="text-start text-lg-center row m-0"
                                  sm={12}
                                  lg={2}
                                >
                                  <div className="m-auto">
                                    {user.role
                                      ? permissionsData.map((permission) => {
                                          if (permission.role === user.role)
                                            return permission.name;
                                          else return false;
                                        })
                                      : "N/D"}
                                  </div>
                                  <hr
                                    className="m-1 p-1 d-block d-lg-none"
                                    style={{ color: "rgba(0,0,0,0.2)" }}
                                  />
                                </Col>
                                <Col
                                  className="text-center text-lg-end row m-0 row"
                                  sm={12}
                                  lg={2}
                                >
                                  <Row className="m-auto p-0">
                                    {CheckPermission(
                                      userPermission && userPermission.role
                                        ? userPermission.role
                                        : "",
                                      user.role
                                    ) &&
                                    user &&
                                    user.role !==
                                      localStorage.getItem(
                                        "robotictech_role"
                                      ) ? (
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={
                                          user.enabled
                                            ? "Desativar o usuário"
                                            : "Ativar o usuário"
                                        }
                                        className={
                                          "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 " +
                                          (user.enabled
                                            ? "btn-outline-danger"
                                            : "btn-outline-success")
                                        }
                                        onClick={() => {
                                          if (
                                            CheckPermission(
                                              userPermission &&
                                                userPermission.role
                                                ? userPermission.role
                                                : "",
                                              user.role
                                            )
                                          ) {
                                            toggleModal({
                                              title: user.enabled
                                                ? "Desabilitar o usuário"
                                                : "Habilitar o usuário",
                                              content:
                                                "Deseja realmente " +
                                                (user.enabled
                                                  ? "desabilitar"
                                                  : "habilitar") +
                                                " o usuário do sistema?",
                                              button: "Sim",
                                              buttonStyle: "success",
                                              buttonAction: user.enabled
                                                ? () =>
                                                    disableEnableUser(
                                                      user.id,
                                                      "disable"
                                                    )
                                                : () =>
                                                    disableEnableUser(
                                                      user.id,
                                                      "enable"
                                                    ),
                                              buttonHref: null,
                                              buttonSec: "Cancelar",
                                              buttonSecStyle:
                                                "outline-secondary",
                                              buttonSecAction: () =>
                                                setModal(false),
                                            });
                                          } else {
                                            toggleModal({
                                              title: "Status do usuário",
                                              content:
                                                "Não é possível alterar o status deste usuário pois sua permissão não possibilita esta ação.",

                                              button: "Ok",
                                              buttonStyle: "outline-secondary",
                                              buttonAction: () =>
                                                setModal(false),
                                            });
                                          }
                                        }}
                                        style={{
                                          borderRadius: "20px",
                                          width: "100%",
                                          height: "1.7em",
                                        }}
                                        type="button"
                                      >
                                        <small>
                                          {user.enabled ? (
                                            <>
                                              <BsFillNodeMinusFill /> Desativar
                                            </>
                                          ) : (
                                            <>
                                              <BsFillNodePlusFill /> Ativar
                                            </>
                                          )}
                                        </small>
                                      </Link>
                                    ) : (
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={
                                          "Você não tem permissão para " +
                                          (user.enabled
                                            ? "desativar"
                                            : "ativar") +
                                          " este usuário"
                                        }
                                        className={
                                          "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 btn-outiline-secondary disabled"
                                        }
                                        to={false}
                                        style={{
                                          borderRadius: "20px",
                                          width: "100%",
                                          height: "1.7em",
                                        }}
                                        type="button"
                                      >
                                        <small>
                                          {user.enabled ? (
                                            <>
                                              <BsFillNodeMinusFill /> Desativar
                                            </>
                                          ) : (
                                            <>
                                              <BsFillNodePlusFill /> Ativar
                                            </>
                                          )}
                                        </small>
                                      </Link>
                                    )}
                                  </Row>
                                  <Row className="m-auto p-0">
                                    {CheckPermission(
                                      userPermission && userPermission.role
                                        ? userPermission.role
                                        : "",
                                      user.role
                                    ) &&
                                    user &&
                                    user.role !==
                                      localStorage.getItem(
                                        "robotictech_role"
                                      ) ? (
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Editar o usuário"
                                        className={
                                          "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 btn-outline-primary"
                                        }
                                        to={"/editarusuario/" + user.id}
                                        style={{
                                          borderRadius: "20px",
                                          width: "100%",
                                          height: "1.7em",
                                        }}
                                        type="button"
                                      >
                                        <small>
                                          <>
                                            <BsFillNodePlusFill /> Editar
                                          </>
                                        </small>
                                      </Link>
                                    ) : (
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Você não tem permissão para editar este usuário"
                                        className={
                                          "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 btn-outline-secondary disabled"
                                        }
                                        to={false}
                                        style={{
                                          borderRadius: "20px",
                                          width: "100%",
                                          height: "1.7em",
                                        }}
                                        type="button"
                                      >
                                        <small>
                                          <>
                                            <BsFillNodePlusFill /> Editar
                                          </>
                                        </small>
                                      </Link>
                                    )}
                                  </Row>
                                  {/* <Row className="m-auto p-0">
                                    <Link
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content="Editar o usuário"
                                      className={
                                        "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 btn-danger"
                                      }
                                      to={null}
                                      onClick={() => {
                                        if (
                                          CheckPermission(
                                            userPermission &&
                                              userPermission.role
                                              ? userPermission.role
                                              : "",
                                            user.role
                                          )
                                        ) {
                                          toggleModal({
                                            title: "Apagar usuário",
                                            content:
                                              "Deseja realmente apagar o usuário do sistema?",
                                            button: "Sim",
                                            buttonStyle: "success",
                                            buttonAction: () =>
                                              deleteUser(user.id),
                                            buttonHref: null,
                                            buttonSec: "Cancelar",
                                            buttonSecStyle: "outline-secondary",
                                            buttonSecAction: () =>
                                              setModal(false),
                                          });
                                        } else {
                                          toggleModal({
                                            title: "Apagar usuário",
                                            content:
                                              "Não é possível alterar o perfil deste usuário pois sua permissão não possibilita esta ação.",

                                            button: "Ok",
                                            buttonStyle: "outline-secondary",
                                            buttonAction: () => setModal(false),
                                          });
                                        }
                                      }}
                                      style={{
                                        borderRadius: "20px",
                                        width: "100%",
                                        height: "1.7em",
                                      }}
                                      type="button"
                                    >
                                      <small>
                                        <>
                                          <BsFillNodePlusFill /> Excluir
                                        </>
                                      </small>
                                    </Link>
                                  </Row> */}
                                </Col>
                              </Row>
                              //   </Link>
                            );
                          } else {
                            return false;
                          }
                        })}
                      </div>
                    ) : (
                      <div className="m-4">Sem usuários disponíveis</div>
                    )
                  ) : (
                    <div className="m-auto text-center">
                      <Spinner
                        color="primary"
                        style={{
                          margin: "auto",
                          width: "100px",
                          height: "100px",
                        }}
                      >
                        Loading...
                      </Spinner>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <PaginationBar
                currentPage={currentPage}
                totalPages={Math.ceil(totalUsers / pageSize)} // Calcula o total de páginas
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </Container>
      ) : CheckPermission(
          userPermission && userPermission.role ? userPermission.role : "",
          "ADMIN"
        ) ? (
        <Container
          className="p-0"
          style={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          {/* Início da exibição da lista de empresas */}
          <div className="container p-0" style={{ marginTop: "10vh " }}>
            <div
              id="basicInformation"
              className="container /*main-content*/ recuadoOut"
            >
              <div className="row pt-4 m-0">
                <Row className="m-0">
                  <Col className="mt-auto mb-auto">
                    <h2 className="m-0 text-start">
                      <div className="m-2 ms-0">
                        <Link
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={"Ir para a página inicial"}
                          className="text-end "
                          style={{ marginRight: "0px" }}
                          to={"/home"}
                        >
                          <BsArrowLeft
                            className="nav-button-circle "
                            style={{ boxShadow: "none" }}
                          />
                        </Link>
                        Empresas
                      </div>
                    </h2>
                    <Tooltip id="my-tooltip" />
                  </Col>
                  <Col lg={4} className="pr-0 m-0 row">
                    <div className="text-end">
                      {/* Início botão de adicionar nova empresa */}

                      {CheckPermission(
                        userPermission && userPermission.role
                          ? userPermission.role
                          : "",
                        "ADMIN"
                      ) ? (
                        <Link
                          className="text-end "
                          style={{ marginRight: "0px" }}
                          to={"/novaempresa"}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Criar uma Nova Empresa"
                        >
                          Nova Empresa
                          <Tooltip id="my-tooltip" />
                          <BsFillPlusCircleFill className="nav-button-circle " />
                        </Link>
                      ) : (
                        ""
                      )}

                      {/* Fim botão de adicionar nova empresa */}
                    </div>
                  </Col>
                </Row>
              </div>
              <div style={{ display: "flex", padding: "5px" }}>
                <div style={{ width: "100%" }}>
                  <hr />
                  <div className="d-none d-lg-block ">
                    <Row
                      className="m-0 p-0"
                      onClick={() => setShowDisabledItens(!showDisabledItens)}
                    >
                      <Link className="row m-0" style={{ width: "auto" }}>
                        <div
                          className="p-0 ms-0 mt-0 mb-0 me-3"
                          style={{
                            width: "20px",
                            height: "20px",
                            border: "1px solid #CCC",
                            borderRadius: "6px",
                          }}
                        >
                          {showDisabledItens ? (
                            <BsCheck
                              className=""
                              style={{
                                width: "100%",
                                height: "100%",
                                marginTop: "-10px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        Mostrar empresas desabilitadas
                      </Link>
                    </Row>
                    <Row className="mb-0 me-2 ms-2 mt-2 p-2">
                      <Col className="text-end row m-0" sm={12} lg={2}>
                        <div className="m-auto"></div>
                      </Col>
                      <Col className="text-start row m-0" sm={12} lg={4}>
                        <div className="m-auto">Nome</div>
                      </Col>
                      <Col className="text-center row m-0" sm={12} lg={4}>
                        <div className="m-auto">Contato</div>
                      </Col>
                      <Col className="text-end row m-0" sm={12} lg={2}>
                        <div className="m-auto">Ações</div>
                      </Col>
                    </Row>
                    <hr className="mt-0 pt-0 me-2 ms-2" />
                  </div>
                  {companies ? (
                    companies.map((comp) => {
                      let showUser = false;
                      if (showDisabledItens) {
                        showUser = true;
                      } else {
                        if (comp.enabled === true) showUser = true;
                      }

                      if (showUser) {
                        return (
                          <Link
                            to={"/companiesManagement/" + comp.id}
                            key={Math.random()}
                          >
                            <Row
                              className={
                                "m-2 p-2 " +
                                (comp.enabled ? "bg-white" : "bg-light")
                              }
                              style={{
                                boxShadow: "0px 0px 5px #CCC",
                                borderRadius: "5px",
                              }}
                            >
                              <Col
                                className="text-center row m-0"
                                sm={12}
                                lg={1}
                                xl={2}
                              >
                                <div className="m-auto">
                                  {comp.logo_image_path ? (
                                    <img
                                      alt=""
                                      src={
                                        comp.logo_image_path +
                                        "?" +
                                        performance.now()
                                      }
                                      style={{ maxWidth: "100%" }}
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src={"/imgs/logo_empty.png"}
                                      style={{ maxWidth: "100%" }}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                className="text-start row m-0"
                                sm={12}
                                lg={4}
                              >
                                <div className="m-auto">
                                  {comp.name ? comp.name : "N/D"} <br />
                                  <small>
                                    {comp.cnpj
                                      ? "CNPJ: " + formatCnpj(comp.cnpj)
                                      : ""}
                                  </small>
                                </div>
                                <hr
                                  className="m-1 p-1 d-block d-lg-none"
                                  style={{ color: "rgba(0,0,0,0.2)" }}
                                />
                              </Col>
                              <Col
                                className="text-start text-lg-center row m-0"
                                sm={12}
                                lg={4}
                              >
                                <div className="m-auto">
                                  {comp.email ? comp.email : ""}
                                  <br />
                                  <small>
                                    {comp.phone && comp.phone.length > 2
                                      ? formatPhone(comp.phone)
                                      : ""}
                                  </small>
                                </div>
                                <hr
                                  className="m-1 p-1 d-block d-lg-none"
                                  style={{ color: "rgba(0,0,0,0.2)" }}
                                />
                              </Col>
                              <Col
                                className="text-center text-lg-end row m-0 row"
                                sm={12}
                                lg={3}
                                xl={2}
                              >
                                <Row className="m-auto p-0">
                                  <Link
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      comp.enabled
                                        ? "Desativar a empresa"
                                        : "Ativar a empresa"
                                    }
                                    className={
                                      "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 " +
                                      (comp.enabled
                                        ? "btn-outline-danger"
                                        : "btn-outline-success")
                                    }
                                    onClick={() => {
                                      if (
                                        CheckPermission(
                                          userPermission && userPermission.role
                                            ? userPermission.role
                                            : "",
                                          //user.role
                                          "ADMIN"
                                        )
                                      ) {
                                        toggleModal({
                                          title: comp.enabled
                                            ? "Desabilitar a empresa"
                                            : "Habilitar a empresa",
                                          content:
                                            "Deseja realmente " +
                                            (comp.enabled
                                              ? "desabilitar"
                                              : "habilitar") +
                                            " a empresa no sistema?",
                                          button: "Sim",
                                          buttonStyle: "success",
                                          buttonAction: comp.enabled
                                            ? () =>
                                                disableEnableCompany(
                                                  comp.id,
                                                  "disable"
                                                )
                                            : () =>
                                                disableEnableCompany(
                                                  comp.id,
                                                  "enable"
                                                ),
                                          buttonHref: null,
                                          buttonSec: "Cancelar",
                                          buttonSecStyle: "outline-secondary",
                                          buttonSecAction: () =>
                                            setModal(false),
                                        });
                                      } else {
                                        toggleModal({
                                          title: "Status do usuário",
                                          content:
                                            "Não é possível alterar o status desta empresa pois sua permissão não possibilita esta ação.",
                                          button: "Ok",
                                          buttonStyle: "outline-secondary",
                                          buttonAction: () => setModal(false),
                                        });
                                      }
                                    }}
                                    style={{
                                      borderRadius: "20px",
                                      width: "100%",
                                      height: "1.7em",
                                    }}
                                    type="button"
                                  >
                                    <small>
                                      {comp.enabled ? (
                                        <>
                                          <BsFillNodeMinusFill /> Desativar
                                        </>
                                      ) : (
                                        <>
                                          <BsFillNodePlusFill /> Ativar
                                        </>
                                      )}
                                    </small>
                                  </Link>
                                </Row>

                                {comp ? (
                                  comp.enabled ? (
                                    <Row className="m-auto p-0">
                                      <Link
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Editar a empresa"
                                        className={
                                          "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 btn-outline-primary"
                                        }
                                        style={{
                                          borderRadius: "20px",
                                          width: "100%",
                                          height: "1.7em",
                                        }}
                                        to={"/editCompany/" + comp.id}
                                        type="button"
                                        alt="Editar usuário"
                                      >
                                        <small>
                                          <BsFillPencilFill /> Editar
                                        </small>
                                      </Link>
                                    </Row>
                                  ) : (
                                    <Row className="m-auto p-0">
                                      <div
                                        className="m-0 p-0"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="É necessário ativar a empresa primeiro."
                                      >
                                        <Link
                                          className={
                                            "ms-auto mt-1 mb-1 btn btsn-item-list pt-0 pb-0 btn-outline-primary disabled"
                                          }
                                          style={{
                                            borderRadius: "20px",
                                            width: "100%",
                                            height: "1.7em",
                                          }}
                                          to={null}
                                          type="button"
                                          alt="Editar usuário"
                                        >
                                          <small>
                                            <BsFillPencilFill /> Editar
                                          </small>
                                        </Link>
                                      </div>
                                    </Row>
                                  )
                                ) : (
                                  ""
                                )}

                                {/* <Row className="m-auto p-0">
                                <Link
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="Excluir a empresa"
                                  className="ms-auto mt-1 mb-1 btn btn-item-list pt-0 pb-0"
                                  style={{
                                    width: "100%",
                                    height: "1.7em",
                                  }}
                                  type="button"
                                  alt="Editar usuário"
                                >
                                  <small>
                                    <BsTrash /> Excluir
                                  </small>
                                </Link>
                              </Row> */}
                              </Col>
                            </Row>
                          </Link>
                        );
                      } else {
                        return false;
                      }
                    })
                  ) : (
                    <div className="m-auto text-center">
                      <Spinner
                        color="primary"
                        style={{
                          margin: "auto",
                          width: "100px",
                          height: "100px",
                        }}
                      >
                        Loading...
                      </Spinner>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <Container
          className="p-0"
          style={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          {/* Início da exibição da lista de empresas */}
          <div className="container p-0" style={{ marginTop: "10vh " }}>
            <div
              id="basicInformation"
              className="container /*main-content*/ recuadoOut"
            >
              <div className="row p-4 m-0">
                <Row className="m-0">
                  <Col className="mt-auto mb-auto">
                    <h2 className="m-2 text-start">
                      Você não tem permissão para acessar esta página
                    </h2>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default CompaniesManagement;
