import React from 'react';
import WireReserveIcon from '../../assets/icons/RESERVA_DE_FIOS.svg';

const WireReserveIconButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={WireReserveIcon} alt={label} data-testid="wirereserver-icon" width="20px" height="20px"/>
    </button>
  );
};

export default WireReserveIconButton;