import React from 'react';
import InsulatorIcon from '../../assets/icons/ISOLADORES.svg';

const InsulatorIconButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={InsulatorIcon} alt={label} data-testid="insulator-icon" width="20px" height="20px"/>
    </button>
  );
};

export default InsulatorIconButton;