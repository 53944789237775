import React from 'react';
import PoleIcon from '../../assets/icons/POSTE_ATIVADO.svg';

const PoleIconButton = ({ label }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={PoleIcon} alt={label} data-testid="pole-icon" width="20px" height="20px"/>
    </button>
  );
};

export default PoleIconButton;